<template>
<footer>

  <footer class="px-3 py-8 bg-white dark:bg-gray-800 text-2 text-gray-500 dark:text-gray-200
                 transition-colors duration-200">
    <div class="flex flex-col">
      <div class="md:hidden mt-7 mx-auto w-11 h-px rounded-full">
      </div>
      <div class="mt-4 md:mt-0 flex flex-col md:flex-row">
        <nav class="flex-1 flex flex-col items-center justify-center md:items-end md:border-r
                    border-gray-100 md:pr-5">
          <router-link aria-current="page" to="/imprint" class="hover:text-secondary-dark
                                                        dark:hover:text-white">
            {{$t("footer.imprint")}}
          </router-link>
          <router-link aria-current="page" to="/privacy" class="hover:text-secondary-dark
                                                        dark:hover:text-white">
            {{$t("footer.privacy")}}
          </router-link>
          <!--a aria-current="page" href="#" class="hover:text-gray-700 dark:hover:text-white">
            {{$t("footer.tos")}}
          </a-->
          <a aria-current="page" href="https://www.tur-advocates.de"
             class="hover:text-secondary-dark dark:hover:text-white">
            {{$t("footer.lawOffice")}}
          </a>
        </nav>
        <div class="mt-4 md:mt-0 flex-1 flex items-center justify-center md:border-r
                    border-gray-100">
          <p class="text-xs px-10">
            {{$t("footer.disclaimer")}}
          </p>
        </div>
        <div class="md:hidden mt-4 mx-auto w-11 h-px rounded-full ">
        </div>
        <div class="mt-7 md:mt-0 flex-1 flex flex-col items-center justify-center md:items-start
                    md:pl-5">
                <span class="text-sm">
                    © 2022
                </span>
          <span class="mt-7 md:mt-1 text-xs">
                    {{$t("footer.createdBy")}}
                </span>
        </div>
      </div>
    </div>
  </footer>

</footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>

</style>
