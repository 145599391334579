<template>
  <div class="shadow-xl rounded-2xl w-full bg-white dark:bg-gray-800 p-4 border border-3">
    <p class="text-secondary-dark dark:text-gray-50 text-xl font-medium mb-4">
      {{ title }}
    </p>
    <p class="dark:text-white text-3xl font-bold">
      <span class="text-xl line-through font-bold text-red-500 opacity-80">{{ oldPrice }}</span>
      {{price}}
      <span class="text-gray-300 text-sm">
            {{ priceAnnotation }}
        </span>
    </p>
    <p class="text-gray-600 dark:text-gray-100  text-xs mt-4">
      {{description}}
    </p>
    <ul class="text-sm text-gray-600 dark:text-gray-100 w-full mt-6 mb-6">
      <li class="mb-3 flex items-center" v-for="item in included" :key="item">
        <svg class="h-6 w-6 mr-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6"
             stroke="currentColor" fill="#10b981" viewBox="0 0 1792 1792">
          <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45
           19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252
            162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5
            279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z">
          </path>
        </svg>
        {{ item }}
      </li>
      <li class="mb-3 flex items-center opacity-50" v-for="item in excluded" :key="item">
        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" class="h-6 w-6 mr-2" fill="red"
             viewBox="0 0 1792 1792">
          <path d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45
          0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90
          90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0
          45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103
          385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5
          385.5-103 385.5 103 279.5 279.5 103 385.5z">
          </path>
        </svg>
        {{ item }}
      </li>
    </ul>
    <a type="button" :href="link" target="_blank" @click="clickBuy()"
            class="py-2 px-4  bg-primary-light hover:bg-primary-dark focus:ring-primary-dark
                   focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200
                   text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2
                   focus:ring-offset-2 rounded-lg ">
      {{ callToAction }}
    </a>
  </div>
</template>
<script>
import mixpanel from 'mixpanel-browser';

export default {
  name: 'PricingCard',
  props: {
    title: String,
    price: String,
    oldPrice: String,
    priceAnnotation: String,
    description: String,
    callToAction: String,
    included: Array,
    excluded: Array,
    link: String,
  },
  methods: {
    clickBuy() {
      mixpanel.track('pricing_card_click', {
        plan: this.title,
        price: this.price,
      });
    },
  },
};
</script>
