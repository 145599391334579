<template>
  <CookieBanner v-if="showCookieBanner" @disable="setConsentCookie('disabled')" @enable="enable()"/>
  <Header class="sticky top-0 z-30"/>
  <router-view class="z-30"/>
  <Footer class="z-30"/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import CookieBanner from '@/components/CookieBanner.vue';
import mixpanel from 'mixpanel-browser';
import { useCookies } from 'vue3-cookies';

const cookieName = 'cookieConsentGranted';
const { cookies } = useCookies();

export default {
  components: { CookieBanner, Footer, Header },
  methods: {
    enableTracking() {
      console.log('Tracking enabled.');
      // Mixpanel
      mixpanel.init('8388696d3d711ba464b2b02f3ab5f35d', { debug: true });

      const loadingTimeMs = window.performance.measure('').duration;
      mixpanel.track('InitialPageLoad', {
        loadingTimeMs,
      });
      mixpanel.identify();

      mixpanel.register_once({ Source: this.source() });
      mixpanel.people.set_once({ Source: this.source() });

      // eslint-disable-next-line
      (function (h, o, t, j, a, r) { h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments); }; h._hjSettings = { hjid: 2901124, hjsv: 6 }; a = o.getElementsByTagName('head')[0]; r = o.createElement('script'); r.async = 1; r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv; a.appendChild(r); }(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv='));
    },
    setConsentCookie(val) {
      cookies.set(cookieName, val);
    },
    enable() {
      this.setConsentCookie('enabled');
      this.enableTracking();
    },
    source() {
      if (document.referrer.search('https?://(.*)google.([^/?]*)') === 0) {
        return 'Google';
      }
      if (document.referrer.search('https?://(.*)bing.([^/?]*)') === 0) {
        return 'Bing';
      }
      if (document.referrer.search('https?://(.*)yahoo.([^/?]*)') === 0) {
        return 'Yahoo';
      }
      if (document.referrer.search('https?://(.*)facebook.([^/?]*)') === 0) {
        return 'Facebook';
      }
      if (document.referrer.search('https?://(.*)twitter.([^/?]*)') === 0) {
        return 'Twitter';
      }
      if (document.referrer.search('https?://(.*)slack.([^/?]*)') === 0) {
        return 'Slack';
      }
      return 'Other';
    },
  },
  created() {
    // Check if consent cookie has been set and only show dialog if it hasn't.
    const cookie = cookies.get(cookieName);
    console.log(`cookie: ${cookie}`);

    if (cookie === 'enabled') {
      this.showCookieBanner = false;
      this.enableTracking();
    } else if (cookie === 'disabled') {
      this.showCookieBanner = false;
    } else {
      this.showCookieBanner = true;
    }
  },
  data() {
    return {
      showCookieBanner: true,
    };
  },
};
</script>
