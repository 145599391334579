<template>
  <section>
    <div class="flex flex-col justify-center items-align-center text-center pb-8 gap-2">
      <span class="text-3xl text-primary-light">{{ $t("pricing.title") }}</span>
      <span class="text-lg text-gray-400"></span>
    </div>
    <div class="flex flex-col md:flex-row gap-8 px-6 lg:px-16">
      <PricingCard v-for="tier in tiers" :key="tier" :title="tier.title" :included="tier.included"
                   :price="tier.price" :priceAnnotation="tier.priceAnnotation"
                   :excluded="tier.excluded" :description="tier.description" :link="tier.link"
                   :callToAction="tier.callToAction" :oldPrice="tier.oldPrice"/>
    </div>
  </section>
</template>

<script>
import PricingCard from '@/components/PricingCard.vue';

export default {
  name: 'Pricing',
  components: { PricingCard },
  computed: {
    tiers() {
      return [
        {
          title: this.$t('pricing.low.title'),
          price: '49 €',
          oldPrice: '99 €',
          priceAnnotation: this.$t('pricing.priceAnnotation'),
          description: this.$t('pricing.low.description'),
          callToAction: this.$t('pricing.cta'),
          included: [
            this.$t('pricing.low.included[0]'),
            this.$t('pricing.low.included[1]'),
          ],
          excluded: [
            this.$t('pricing.low.excluded[0]'),
            this.$t('pricing.low.excluded[1]'),
            this.$t('pricing.low.excluded[2]'),
          ],
          link: this.$t('pricing.low.paymentLink'),
        },
        {
          title: this.$t('pricing.medium.title'),
          price: '89 €',
          oldPrice: '129 €',
          priceAnnotation: this.$t('pricing.priceAnnotation'),
          description: this.$t('pricing.medium.description'),
          callToAction: this.$t('pricing.cta'),
          included: [
            this.$t('pricing.medium.included[0]'),
            this.$t('pricing.medium.included[1]'),
            this.$t('pricing.medium.included[2]'),
          ],
          excluded: [
            this.$t('pricing.medium.excluded[0]'),
            this.$t('pricing.medium.excluded[1]'),
          ],
          link: this.$t('pricing.medium.paymentLink'),
        },
        {
          title: this.$t('pricing.high.title'),
          price: '399 €',
          oldPrice: '499 €',
          priceAnnotation: this.$t('pricing.priceAnnotation'),
          description: this.$t('pricing.high.description'),
          callToAction: this.$t('pricing.cta'),
          included: [
            this.$t('pricing.high.included[0]'),
            this.$t('pricing.high.included[1]'),
            this.$t('pricing.high.included[2]'),
            this.$t('pricing.high.included[3]'),
            this.$t('pricing.high.included[4]'),
          ],
          excluded: [],
          link: this.$t('pricing.high.paymentLink'),
        },
      ];
    },
  },
};
</script>
