import { loadStripe } from '@stripe/stripe-js';
import { defineComponent, ref, onBeforeMount } from 'vue';

const key = 'pk_test_51KicpGIuaCs5rz7604546lfXyELZzU3Lc39WKU5ueRF4E53EJ6lDNacZDeFURLgE3PRkEfQKt'
              + 'JpAXUIXizOmRpdJ00jloAqN2B';

export default defineComponent({
  setup() {
    onBeforeMount(() => {
      const stripeLoaded = ref(false);
      const stripePromise = loadStripe(key);
      stripePromise.then(() => {
        stripeLoaded.value = true;
      });
    });
  },
});
