import { createApp } from 'vue';
import { globalCookiesConfig } from 'vue3-cookies';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import i18n from './i18n';
import stripe from './stripe';

globalCookiesConfig({
  expireTimes: '30d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'None',
});

createApp(App)
  .use(i18n)
  .use(router)
  .use(stripe)
  .mount('#app');
