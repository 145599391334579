<template>
  <header class="text-gray-600 body-font bg-background-primary capitalize z-50 font-normal
                 shadow-md rounded-b-xl">
    <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <router-link :to="{name: 'Home', hash: '#hero'}" class="cursor-pointer">
        <div class="relative">
          <span class="text-3xl text-primary-light font-black origin-center">t</span>
          <span class="uppercase font-black text-primary-light text-xl">
          ransparenzregister</span>
          <span class="text-2xl text-primary-dark font-black">1</span>
          <span class="text-3xl text-primary-dark font-black">2</span>
          <span class="text-4xl text-primary-dark font-black">3</span>
          <span class="uppercase">.de</span>
        </div>
      </router-link>
      <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center flex flex-row
                  gap-6">
      </nav>
      <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center">
        <LanguagePicker class="mr-5"/>
        <router-link :to="{name: 'Home', hash: '#hero'}">
          <a class="mr-5 cursor-pointer"
             :class="inView === 'hero' ? 'text-primary-light hover:text-primary-dark'
                                       : 'hover:text-gray-900'">
            {{ $t('header.hero') }}</a>
        </router-link>
        <router-link :to="{name: 'Home', hash: '#faq'}"
                     :class="inView === 'faq' ? 'text-primary-light hover:text-primary-dark'
                                               : 'hover:text-gray-900'">
          <a class="mr-5 cursor-pointer">
            {{ $t('header.faq') }}</a>
        </router-link>
        <router-link :to="{name: 'Home', hash: '#pricing'}"
                     :class="inView === 'pricing' ?
                     'text-primary-light hover:text-primary-dark' : 'hover:text-gray-900'">
          <a class="mr-5 cursor-pointer" href="">
            {{ $t('header.pricing') }}</a>
        </router-link>
        <router-link :to="{name: 'Home', hash: '#testimonials'}"
                     :class="inView === 'testimonials' ?
                     'text-primary-light hover:text-primary-dark' : 'hover:text-gray-900'">
          <a class="mr-5 cursor-pointer">
            {{ $t('header.testimonials') }}</a>
        </router-link>
      </nav>
      <a class="inline-flex items-center hover:bg-primary-dark border-0 py-1 px-3 hidden
                     focus:outline-none text-white bg-primary-light rounded text-base mt-4 md:mt-0"
         target="_blank">
        {{ $t('header.callToAction') }}
        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
             stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </svg>
      </a>
    </div>
  </header>
</template>

<script>
import LanguagePicker from '@/components/LanguagePicker.vue';

const SECTIONS = [
  '#hero',
  '#faq',
  '#pricing',
  '#testimonials',
];

export default {
  name: 'Header',
  components: {
    LanguagePicker,
  },
  data() {
    return {
      inView: this.$route.name === 'Home' ? window.location.hash.substr(1) : '',
    };
  },
  methods: {
    pixelsVisibleOnScreen(selector) {
      const c = (window.innerHeight || document.documentElement.clientHeight);
      const boundingRect = document.querySelector(selector).getBoundingClientRect();
      const { y } = boundingRect;
      const h = boundingRect.height;
      if (y + h < 0) return 0;
      if (y < 0) return Math.max(h - y, 0);
      if (y + h <= c) return h;
      return Math.max(c - y, 0);
    },
    determineSelectorInView() {
      const index = SECTIONS.map(this.pixelsVisibleOnScreen)
        .reduce((iMax, x, i, arr) => (x > arr[iMax] ? i : iMax), 0);
      return SECTIONS[
        index
      ].substr(1);
    },
  },
  mounted() {
    document.addEventListener('scroll', () => {
      // Only do the highlighting if on Homepage
      this.inView = this.$route.name === 'Home' ? this.determineSelectorInView() : '';
    }, {
      passive: true,
    });
  },
};
</script>
