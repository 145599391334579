export default {
  "header": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonials"])}
  },
  "hero": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparency Register"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mandatory for all"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All German UGs and GmbHs must be registered in the Transparency Register by <span class=\"text-secondary-light underline\">30.06.2022</span> - Otherwise there is a risk of heavy fines!"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register now"])},
    "widget": {
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAYS"])},
      "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOURS"])},
      "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MINUTES"])},
      "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only"])},
      "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["until the deadline."])}
    }
  },
  "pricing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have the right solution for you!"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "priceAnnotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exlc. VAT."])},
    "low": {
      "paymentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://buy.stripe.com/fZedTk5ymg59cfeaEE"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect for freelancers"])},
      "included": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration service"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One natural person as a shareholder"])}
      ],
      "excluded": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free extract from the commercial register"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["legal entities as a shareholders"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fictitious beneficial owners"])}
      ]
    },
    "medium": {
      "paymentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://buy.stripe.com/7sI8z0d0ObOT6UU3cd"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startup"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfect for most medium-sized companies"])},
      "included": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration service"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 6 natural persons as a shareholders"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free extract from the commercial register"])}
      ],
      "excluded": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["legal entities as a shareholders"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fictitious beneficial owners"])}
      ]
    },
    "high": {
      "paymentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://buy.stripe.com/6oE16y4uidX16UU5km"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprehensive Service for complex structures"])},
      "included": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration service"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 6 natural persons as a shareholders"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free extract from the commercial register"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up to 2 legal entities as a shareholders"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fictitious beneficial owners"])}
      ],
      "excluded": [
        
      ]
    }
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
    "items": [
      {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the Transparency Register?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Transparency Register has implemented the EU Money Laundering Directive (EU Directive 2015/849) since 2017 and serves to prevent money laundering and terrorism financing."])}
      },
      {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who must be entered in the Transparency Register?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most legal forms such as GmbH, UG, KG, OHG, e.V. etc. must be registered. Sole proprietors (\"Einzelunternehmer\"), registered traders (\"e.K.\") and GbRs are exempt from the requirement."])}
      },
      {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What information is necessary?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to be able to register your UG or GmbH, we need a current excerpt from the commercial register, a list of shareholder(s) and the current registration address and the date of birth of the shareholders and the managing director(s)."])}
      },
      {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What happens if I do not register at all or register too late?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generally, a fine of €100 to €500 is imposed under Section 56(1) Nos 52-56 of the German Money Laundering Act (GWG). However, this amount can be increased up to 100 times depending on the anual revenue of the company."])}
      }
    ]
  },
  "footer": {
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operated by TUR ADVOCATES Rechtsanwaltsgesellschaft mbH"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprint"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Privacy"])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGBs"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: The information offered on this website do not constitute legal advice. This site is not operated by the Federal Republic of Germany nor the Bundesanzeigerverlag."])},
    "lawOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Law Office"])}
  },
  "socialProof": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testimonials"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
    "managingDirector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing Director of an UG"])},
    "founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startup Founder"])},
    "quote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I didn't feel like dealing with the bureaucracy myself. You simply got it done within a very short time and almost without my intervention. Thank you very much for the great work!"])},
    "quote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast, reliable and uncomplicated."])}
  },
  "cookieBanner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are using 3rd-party cookies 🍪"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To deliver and continuously improve our services, we are using third party products and services."])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow All"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue without non-essential Cookies"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])}
  },
  "404": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Home"])}
  }
}