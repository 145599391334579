<template>
<section>
  <div class="max-w-screen-xl mx-auto p-8 flex flex-col items-center">
    <h2 class="text-3xl text-primary-light leading-9 border-gray-100 mb-12">
      {{$t('faq.title')}}
    </h2>
    <ul class="flex items-center lg:items-start gap-8 flex-wrap">
      <li class="lg:w-2/5" v-for="i in 4" :key="i">
        <p class="text-lg font-medium leading-6 text-secondary-light">
          {{$t(`faq.items[${i - 1}].question`)}}
        </p>
        <p class="text-base leading-6 text-gray-500 mt-2">
          {{$t(`faq.items[${i - 1}].answer`)}}
        </p>
      </li>
    </ul>
  </div>

</section>
</template>

<script>
export default {
  name: 'FAQ',
};
</script>

<style scoped>

</style>
