<template>
<section class="w-full flex flex-col items-center my-10">
  <h2 class="text-3xl text-primary-light">{{$t("socialProof.title")}}</h2>
  <div class="w-full md:w-2/3 flex flex-col md:flex-row gap-4 mb-8 md:mb-0 flex-between items-center
              p-8 h-100">
    <div class="bg-background-accent dark:bg-gray-800 w-72 mx-auto rounded-xl p-4 min-h-full">
            <span class="font-bold text-secondary-light text-lg">“</span>
      <p class="text">
        {{$t("socialProof.quote1")}}
      </p>
        <span class="font-bold text-secondary-light text-lg">”</span>
      <div class="flex items-center mt-4">
        <a class="block relative">
          <img alt="profil" src="@/assets/persons/person1.jpeg"
               class="mx-auto object-cover rounded-full h-10 w-10 "/>
        </a>
        <div class="flex flex-col ml-2 justify-between">
                <span class="font-semibold text-secondary-light text-sm">
                    Mauritz {{$t("socialProof.from")}} Berlin
                </span>
          <span class="dark:text-gray-400 text-xs flex items-center">
                    {{$t("socialProof.managingDirector")}}
                </span>
        </div>
      </div>
    </div>
    <div class="bg-background-accent dark:bg-gray-800 w-72 mx-auto rounded-xl p-4 min-h-full">
      <span class="font-bold text-secondary-light text-lg">“</span>
      <p class="text">
        {{$t("socialProof.quote2")}}
      </p>
      <span class="font-bold text-secondary-light text-lg">”</span>
      <div class="flex items-center mt-4">
        <a class="block relative">
          <img alt="profil" src="@/assets/persons/person2.jpeg"
               class="mx-auto object-cover rounded-full h-10 w-10 "/>
        </a>
        <div class="flex flex-col ml-2 justify-between">
                <span class="font-semibold text-secondary-light text-sm">
                    Anna {{$t("socialProof.from")}} Berlin
                </span>
          <span class="dark:text-gray-400 text-xs flex items-center">
                    {{$t("socialProof.founder")}}
                </span>
        </div>
      </div>
    </div>
  </div>

</section>
</template>

<script>
export default {
  name: 'SocialProof',
};
</script>

<style scoped>

</style>
