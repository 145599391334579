export default {
  "header": {
    "hero": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "testimonials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenstimmen"])}
  },
  "hero": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparenz-"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registerpflicht für alle"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle deutschen UGs und GmbHs müssen bis zum <span class=\"text-secondary-light underline\">30.06.2022</span> verpflichtend ins Transparentregister eingetragen werden - Ansonsten drohen hohe Bußgelder!"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt Eintragen Lassen"])},
    "widget": {
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAGE"])},
      "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STUNDEN"])},
      "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MINUTEN"])},
      "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur noch"])},
      "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis Fristablauf."])}
    }
  },
  "pricing": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben das richtige Angebot für Sie!"])},
    "cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellen"])},
    "priceAnnotation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zzgl. MwSt."])},
    "low": {
      "paymentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://buy.stripe.com/fZedTk5ymg59cfeaEE"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelancer"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideal für Solo-Selbstständige"])},
      "included": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintragungsservice"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine natürliche Person als Gesellschafter"])}
      ],
      "excluded": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloser Handelsregisterauszug"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Personen als Gesellschafter"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiktive wirtschaftlich Berechtigte"])}
      ]
    },
    "medium": {
      "paymentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://buy.stripe.com/7sI8z0d0ObOT6UU3cd"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startup"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideal für die meisten UGs und GmbHs"])},
      "included": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintragungsservice"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis zu 6 natürliche Personen als Gesellschafter"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloser Handelsregisterauszug"])}
      ],
      "excluded": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juristische Personen als Gesellschafter"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiktive wirtschaftlich Berechtigte"])}
      ]
    },
    "high": {
      "paymentLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://buy.stripe.com/6oE16y4uidX16UU5km"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umfänglicher Service für komplexere Strukturen"])},
      "included": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintragungsservice"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis zu 6 natürliche Personen als Gesellschafter"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenloser Handelsregisterauszug"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis zu 5 Juristische Personen als Gesellschafter"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiktive wirtschaftlich Berechtigte"])}
      ],
      "excluded": [
        
      ]
    }
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
    "items": [
      {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist das Transparentzregister?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Transparenzregister setzt seit 2017 die EU-Geldwäsche-Richtlinie (EU-Richtlinie 2015/849) um und dient der Verhinderung von Geldwäsche und Terrorismusfinanzierung."])}
      },
      {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer muss sich ins Transparentzregister eintragen lassen?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Rechtsformen wie GmbH, UG, KG, OHG, e.V. etc. müssen eingetragen werden. Ausgenommen von der Pflicht sind Einzelunternehmer, eingetragene Kaufleute und GbRs."])}
      },
      {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Angaben sind notwendig um sich eintragen zu lassen?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Ihre UG oder GmbH eintragen zu können brauchen wir einen aktuellen Handelsregisterauszug, Liste der Gesellschafter und die aktuelle Meldeanschrift und das Geburtsdatum dieser und des bzw. der Geschäftsführer."])}
      },
      {
        "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was passiert, wenn ich mich garnicht oder zu spät eintrage?"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Regel wird nach § 56 Abs. 1 Nr. 52-56 Geldwäschegesetz ein Bußgeld von 100 bis 500 € verhängt. Dieser Betrag kann allerdings abhängig vom Umsatz des Unternehmens bis auf das 100-fache erhöht werden."])}
      }
    ]
  },
  "footer": {
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistungserbringer ist die TUR ADVOCATES Rechtsanwaltsgesellschaft mbH"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGBs"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Bei den auf dieser Webseite angebotenen Informationen handelt es sich nicht um Rechtsberatung. Diese Webseite wird weder von der Bundesrepublik Deutschland noch vom Bundesanzeigerverlag betrieben."])},
    "lawOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanzlei"])}
  },
  "socialProof": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenstimmen"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aus"])},
    "managingDirector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsführer einer UG"])},
    "founder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startup-Gründerin"])},
    "quote1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich hatte gar keine Lust mich selber um diese Bürokratie zu kümmern. Ihr habt das innerhalb kürzester Zeit und fast ohne mein Zutun einfach fertig gemacht. Vielen Dank!"])},
    "quote2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnell, zuverlässig und unkompliziert."])}
  },
  "cookieBanner": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir benutzen Cookies 🍪"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Erbringung und kontinuierlichen Verbesserung unserer Dienstleistungen nutzen wir Produkte und Dienstleistungen Dritter."])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles erlauben"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter ohne zu akzeptieren"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])}
  },
  "404": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite Nicht Gefunden"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Startseite"])}
  }
}