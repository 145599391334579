<template>
  <div class="w-screen relative">
    <hero id="hero"/>
    <FAQ class="pt-16" id="faq"/>
    <pricing class="pt-40" id="pricing"/>
    <SocialProof class="mt-16 pt-16" id="testimonials"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from '@/components/Hero.vue';
import Pricing from '@/components/Pricing.vue';
import FAQ from '@/components/FAQ.vue';
import SocialProof from '@/components/SocialProof.vue';

export default {
  name: 'Home',
  components: {
    SocialProof,
    FAQ,
    Pricing,
    Hero,
  },
};
</script>
