<template>

  <div class="bg-white dark:bg-gray-800 flex relative z-20 items-center overflow-hidden">
    <div class="container mx-auto px-6 flex relative py-16">
      <div class="sm:w-2/3 lg:w-2/5 flex flex-col relative z-20">
            <span class="w-20 h-2 bg-secondary-dark dark:bg-white mb-12">
            </span>
        <h1 class="uppercase text-3xl sm:text-4xl font-black flex flex-col
                   leading-none dark:text-white text-gray-800 text-primary-dark">
            {{ $t("hero.title1") }}<br/>{{ $t("hero.title2") }}
        </h1>
        <p class="text-sm sm:text-base text-gray-700 dark:text-white pt-4"
           :innerHTML="$t('hero.text')">
        </p>
        <div class="flex mt-8">
          <router-link :to="{ name: 'Home', hash: '#pricing' }">
            <button class="uppercase py-2 px-4 rounded-lg bg-primary-dark border-2
                               text-white text-md mr-4 hover:bg-transparent hover:text-primary-dark
                               hover:border-primary-dark border-transparent" @click="ctaClicked()">
              {{ $t("hero.cta") }}
            </button>
          </router-link>
          <a href="#"
             class="uppercase py-2 px-4 rounded-lg bg-transparent border-2 border-primary-light
                    text-primary-light dark:text-white hover:bg-primary-light hover:text-white
                    text-md hidden">
            Mehr Erfahren
          </a>
        </div>
      </div>
      <div class="invisible lg:visible sm:w-1/3 lg:w-3/5 relative flex justify-center px-10">
        <!--<img src="@/assets/logo.png" class="max-w-xs md:max-w-sm m-auto"/>-->
        <div class="bg-background-accent w-80 h-fit rounded-lg shadow-lg flex flex-col px-8
                    justify-center gap-6 py-10">
          <div class="h-12 flex justify-center">
            <img src="@/assets/bundesadler.png"/>
          </div>
          <span class="text-secondary-dark text-2xl">{{ $t("hero.widget.line1") }}</span>
          <div class="flex flex-row w-full relative w-full relative justify-between pb-11 pt-3">
            <div class="bg-background-primary countdown-box flex justify-center rounded-xl
                        content-center relative text-secondary-dark">
              <span class="text-2xl py-5">{{Math.floor(daysDiff)}}</span>
              <span class="absolute -bottom-7 text-sm">{{ $t("hero.widget.day") }}</span>
            </div>
            <div class="bg-background-primary countdown-box flex justify-center rounded-xl
                        content-center relative text-secondary-dark">
              <span class="text-2xl py-5">{{Math.floor(hoursDiff)}}</span>
              <span class="absolute -bottom-7 text-sm">{{ $t("hero.widget.hour") }}</span>
            </div>
            <div class="bg-background-primary countdown-box flex justify-center rounded-xl
                        content-center relative text-secondary-dark">
              <span class="text-2xl py-5">{{Math.floor(minsDiff)}}</span>
              <span class="absolute -bottom-7 text-sm">{{ $t("hero.widget.minute") }}</span>
            </div>
          </div>
          <span class="text-secondary-dark text-2xl">{{ $t("hero.widget.line2") }}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import mixpanel from 'mixpanel-browser';

export default {
  name: 'Hero',
  data() {
    return {
      date: '06/30/2022 00:00',
      dateObj: undefined,
    };
  },
  computed: {
    dateDiffUnix() {
      return this.dateObj - Date.now();
    },
    daysDiff() {
      return this.dateDiffUnix / (1000 * 3600 * 24);
    },
    hoursDiff() {
      const numberOfDays = Math.abs(this.daysDiff) - Math.floor(Math.abs(this.daysDiff));
      return numberOfDays * 24;
    },
    minsDiff() {
      const numberOfHours = Math.abs(this.hoursDiff) - Math.floor(Math.abs(this.hoursDiff));
      return numberOfHours * 60;
    },
  },
  mounted() {
    this.dateObj = new Date(this.date).getTime();
  },
  methods: {
    ctaClicked() {
      mixpanel.track('hero_cta_clicked');
    },
  },
};
</script>

<style scoped>
.countdown-box {
  position: relative;
  width: 25%;
  height: 0;
  padding-bottom: 25%;
}
</style>
